import axios from "axios";
import authHeader from "./auth-header";

const API_URL = 'https://frate-planning.aelia-informatique.fr:28044/api/v1/questionnaire/';
// const API_URL = 'http://192.168.0.59/api/v1/questionnaire/';

class QuestionnaireService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getQuestionnaire(idQuestionnaire) {
    return axios.get(API_URL + "getQuestionnaireById/" + idQuestionnaire, { headers: authHeader() });
  }

  verifierQuestionnaire(idQuestionnaire, texteExecVerif) {
    return axios.post(
      API_URL + "verifierQuestionnaire",
      {
        idQuestionnaire: idQuestionnaire,
        texteExecVerif: texteExecVerif,
      },
      {
        headers: authHeader(),
      }
    );
  }

  sendQuestionnaire(idAppli, numInscription, idAction, idQuestionnaire, texteReponseAEnvoyer, texteCommentaireGlobal) {
    return axios.post(
      API_URL + "postQuestionnaire",
      {
        idAppli: idAppli,
        numInscription: numInscription,
        idAction: idAction,
        idQuestionnaire: idQuestionnaire,
        texteReponseAEnvoyer: texteReponseAEnvoyer,
        texteCommentaireGlobal: texteCommentaireGlobal,
      },
      {
        headers: authHeader(),
      }
    );
  }

  rechercheQuestionnaire(idAppli, idInscription) {
    return axios.post(
      API_URL + "rechercheQuestionnaire",
      {
        idAppli: idAppli,
        idInscription: idInscription,
      },
      {
        headers: authHeader(),
      }
    );
  }

  rechercheQuestionnaireParIdAnonyme(idAnonyme) {
    return axios.post(
      API_URL + "rechercheQuestionnaireParIdAnonyme",
      {
        idAnonyme: idAnonyme,
      },
      {
        headers: authHeader(),
      }
    );
  }

}

export default new QuestionnaireService();
