<template>
    <div class="w3-content">
        <div id="enteteQuestionnaire">
            <br />
            Choix de l'appli : 
            <select class="w3-border" name="noAppli" id="noAppli" style="padding: 3px">
                <option value="1" selected>OFII</option>
                <option value="2">Autres formations</option>
            </select>

            Saisie du numéro d'inscription :
            <input name="noInscription" id="noInscription" type="text" class="w3-theme-l5  inputQuestionnaire">
            
            <button class="btFrate" v-on:click="recherche()">
                Vérification
            </button>

            <div id="repRecherche" style="display: none;">
                <hr>
                <span id="repTexte"></span>
                <button id="repBouton" class="btFrate" v-on:click="getQuestionnaire()" style="display: none;">
                    Commencer le questionnaire
                </button>
            </div>
        </div>

        <div id="questionnaireDiv" style="display: none;">
            <table>
                <tr>
                    <td>
                        <h1><b><span id="monTitre"></span></b></h1>
                    </td>
                    <td>
                        <img alt="LogoFrate" src="img/LogoFrate.png" style="width: 60%"/>
                    </td>
                </tr>
            </table>

            <div id="monQuestionnaire"></div>

            <h2><b>Commentaires :</b></h2>
            <div id="commentaireGlobal" class="textbox" contenteditable="true" style="box-sizing: border-box;outline: 1px solid black;">
            
            </div>
            <hr>
            <br />
            <button v-on:click="validQuestionnaire()" style="width: 100%;" type="submit" class="btFrate signupbtn w3-btn">
                Valider
            </button>
            <hr>
            <br />
        </div>
        <input type="hidden" id="questionnaireId" value="0">
        <input type="hidden" id="actionId" value="0">
    </div>       
</template>

<script>

import QuestionnaireService from "../services/questionnaire.service";

export default {   
    methods: {
        getQuestionnaire() {
            let idQuestionnaire = document.getElementById("questionnaireId").value;
            document.getElementById("questionnaireDiv").style.display = "none";
            document.getElementById("monQuestionnaire").textContent = "";
            document.getElementById("commentaireGlobal").textContent = "";

            if (!idQuestionnaire) {
                document.getElementById("repTexte").textContent = 'Aucun questionnaire n\'est paramétré pour cette inscription';
                document.getElementById("repBouton").style.display="none";
                document.getElementById("questionnaireId").value = 0;
                document.getElementById("actionId").value = 0;
                return;
            }

            document.getElementById("enteteQuestionnaire").style.display = "none";

            QuestionnaireService.getQuestionnaire(idQuestionnaire).then((response) => {
                if (response.data.length) {
                    document.getElementById("questionnaireDiv").style.display = "block"
                    let titre = response.data[0]['LibQuestionnaire'];
                    document.getElementById("monTitre").innerHTML = titre;
                    let data = JSON.parse(response.data[0]['R']);
                    let divGroupeQuestion = '';
                    let divQuestionComplementaire = '';
                    let divQuestion = '';
                    let divBar = "";
                    let codeHexaCouleur = "";
                    let monImage = "";
                    let monTexte = "";

                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < data[i]['T2'].length; j++) {
                            if (data[i]['T2'][j]['LibComplementaire']) {
                                divQuestionComplementaire = "&nbsp;&nbsp;&nbsp;<b>" + data[i]['T2'][j]['LibComplementaire'] + "  </b><br>"
                                                            + '<div class="textbox" contenteditable="true"'
                                                            + ' style="box-sizing: border-box;outline: 1px solid black;margin-left:20px">'
                                                            + "</div>";
                            }
                            if (data[i]['T2'][j]['IdTypeQuestion'] == 1) {
                                divBar = '<div class="bar-v">';
                            } else {
                                divBar = '<div class="bar-' + data[i]['T2'][j]['NbReponse'] + '">';
                            }

                            for (let k = 0; k < data[i]['T2'][j]['T3'].length; k++) {
                                if (data[i]['T2'][j]['T3'][k]['CodeHexaCouleur']) {
                                    codeHexaCouleur = "color: " + data[i]['T2'][j]['T3'][k]['CodeHexaCouleur'];
                                }
                                if (data[i]['T2'][j]['T3'][k]['CheminImage']) {
                                    monImage = '<br><img alt="reponse" style="height: 50px" src="img/' + data[i]['T2'][j]['T3'][k]['CheminImage'] + '"><br>';
                                }

                                if (data[i]['T2'][j]['T3'][k]['IdTypeQuestion'] == 1) {
                                    monTexte = '&nbsp;&nbsp;&nbsp;- ';
                                }
                                monTexte = monTexte + data[i]['T2'][j]['T3'][k]['TexteReponse'];

                                divQuestion = divQuestion + '<span onClick="'
                                                + "document.getElementById('spanReponse-" 
                                                + data[i]['T2'][j]['T3'][k]['IdReponse'] 
                                                + "').parentElement.querySelectorAll('.itemReponse').forEach(el => el.classList.remove('active'));"
                                                + "document.getElementById('"
                                                + 'spanReponse-' + data[i]['T2'][j]['T3'][k]['IdReponse'] + "'" 
                                                + ').classList.add(' + "'active'" + ')" id="spanReponse-' + data[i]['T2'][j]['T3'][k]['IdReponse'] + '"';
                                divQuestion = divQuestion + ' class="itemReponse" style="display:inline-block;height: auto;' + codeHexaCouleur + '">';
                                divQuestion = divQuestion + monImage;
                                divQuestion = divQuestion + monTexte;
                                divQuestion = divQuestion + '</span>';

                                codeHexaCouleur = "";
                                monImage = "";
                                monTexte = "";
                            }

                            divGroupeQuestion = divGroupeQuestion + "<div id='divQuestion-" + data[i]['T2'][j]['IdQuestion']  + "' class='w3-container'>"
                                        + "<b>" + data[i]['T2'][j]['LibQuestion']  + "</b>"
                                        + "<br>"
                                        + divBar
                                        + divQuestion
                                        + "</div>"
                                        + divQuestionComplementaire
                                        + "<hr><br /></div>";
                            divQuestionComplementaire = "";
                            divQuestion = "";
                        }

                        document.getElementById("monQuestionnaire").innerHTML = document.getElementById("monQuestionnaire").innerHTML + "<div><br /><h2><b>" 
                                                                                + data[i]['LibGroupeQuestion'] 
                                                                                + "</b></h2>"
                                                                                + divGroupeQuestion
                                                                                + "</div>";

                        divGroupeQuestion = "";
                    }
                }
            }, (error) => {
                this.loading = false;
                this.messageFicheAnalytique = "Cette page rencontre des difficultés techniques";
            });
        },

        validQuestionnaire() {
            let idAppli = document.getElementById("noAppli").value;
            let numInscription = document.getElementById("noInscription").value;
            let idQuestionnaire = document.getElementById("questionnaireId").value;
            let idAction = document.getElementById("actionId").value;

            let idTexte = "";
            let idNum = "";
            let idQuestion = "";
            let texteReponseAEnvoyer = "";
            let texteReponseAEnvoyerVerif = "";
            let texteComplementaire = "";
            let texteCommentaireGlobal = document.getElementById("commentaireGlobal").textContent;

            let mesReponses = document.getElementsByClassName('active');
            for (let reponse of mesReponses) {
                idTexte = reponse.id;
                idNum = idTexte.replace("spanReponse-", "");

                idQuestion = document.getElementById(idTexte).parentElement.parentElement.id.replace("divQuestion-", "");

                texteComplementaire = "";
                if (document.getElementById(idTexte).parentElement.parentElement.getElementsByClassName('textbox')[0]) {
                    texteComplementaire = document.getElementById(idTexte).parentElement.parentElement.getElementsByClassName('textbox')[0].textContent;
                }
                texteReponseAEnvoyer      = texteReponseAEnvoyer.replace(/'/g, " ") + idNum + '|:|' + texteComplementaire + '|;|';
                texteReponseAEnvoyerVerif = texteReponseAEnvoyerVerif.replace(/'/g, " ") + idQuestion + ';';
            }

            QuestionnaireService.verifierQuestionnaire(idQuestionnaire, texteReponseAEnvoyerVerif).then((response) => {
                let repTexte = response.data['Texte'];

                if (repTexte == '') {
                    QuestionnaireService.sendQuestionnaire(idAppli, numInscription, idAction, idQuestionnaire, texteReponseAEnvoyer, texteCommentaireGlobal);
                    document.getElementById("questionnaireDiv").style.display = "none";
                    document.getElementById("repRecherche").style.display = "none";
                    document.getElementById("questionnaireId").value = 0;
                    document.getElementById("actionId").value = 0;
                    document.getElementById("noInscription").value = "";
                    document.getElementById("monQuestionnaire").textContent = "";
                    document.getElementById("commentaireGlobal").textContent = "";
                    document.getElementById("enteteQuestionnaire").style.display = "block";
                } else {
                    alert(repTexte);
                }
            }, (error) => {
                document.getElementById("repTexte").textContent = "Cette page rencontre des difficultés techniques";
            } )

            
        },

        recherche() {
            let idAppli = document.getElementById('noAppli').value;
            let idInscription = document.getElementById('noInscription').value;

            document.getElementById("questionnaireDiv").style.display = "none";
            document.getElementById("monQuestionnaire").textContent = "";
            document.getElementById("commentaireGlobal").textContent = "";

            QuestionnaireService.rechercheQuestionnaire(idAppli, idInscription).then((response) => {
                if (response.data['Error']) {
                    document.getElementById("repTexte").textContent = response.data['Error'];
                    document.getElementById("repRecherche").style.display="block";
                    document.getElementById("repBouton").style.display="none";
                    document.getElementById("questionnaireId").value = 0;
                    document.getElementById("actionId").value = 0;
                    return;
                }

                let repTexte           = response.data[0]['Texte'];
                let repNom             = response.data[0]['Nom'];
                let repPrenom          = response.data[0]['Prenom'];
                let repIdAction        = response.data[0]['IdAction'];
                let repNomAction       = response.data[0]['NomAction'];
                let repIdQuestionnaire = response.data[0]['IdQuestionnaire'];

                if (repTexte == '') {
                    document.getElementById("repTexte").textContent = repNom + ' ' + repPrenom + ' : ' + repNomAction;
                    document.getElementById("repRecherche").style.display="block";
                    document.getElementById("repBouton").style.display="inline";
                    document.getElementById("questionnaireId").value = repIdQuestionnaire;
                    document.getElementById("actionId").value = repIdAction;
                } else {
                    document.getElementById("repTexte").textContent = repTexte;
                    document.getElementById("repRecherche").style.display="block";
                    document.getElementById("repBouton").style.display="none";
                    document.getElementById("questionnaireId").value = 0;
                    document.getElementById("actionId").value = 0;
                }
            }, (error) => {
                document.getElementById("repTexte").textContent = "Cette page rencontre des difficultés techniques";
                document.getElementById("repRecherche").style.display="block";
            } )
        },
    },
};

</script>

<style>

</style>